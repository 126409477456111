import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import AdminHomePage from './pages/AdminHomePage';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/admin" element={<ProtectedRoute><AdminHomePage /></ProtectedRoute>} />
        <Route path="/" element={<Navigate replace to="/login" />} />
      </Routes>
    </Router>
  );
}

function ProtectedRoute({ children }) {
  const [isValidToken, setIsValidToken] = useState(null);

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsValidToken(false);
        return;
      }

      try {
        await axios.get(`${apiBaseUrl}/admin/token/validate`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setIsValidToken(true);
      } catch (error) {
        console.error('Token validation error:', error);
        setIsValidToken(false);
      }
    };

    validateToken();
  }, []);

  if (isValidToken === null) {
    return <div>Checking authentication...</div>;
  }

  return isValidToken ? children : <Navigate replace to="/login" />;
}

export default App;