import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/img/ai-chat5.png';

import { Form, Input, Button, Typography } from 'antd';

const { Title } = Typography;

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


  const handleSubmit = async (e) => {
    //e.preventDefault();

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    try {
      const response = await fetch(`${apiBaseUrl}/admin/token`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Login response:', data.token);
      localStorage.setItem('token', data.token);
      localStorage.setItem('username', username);
      localStorage.setItem('urlslug', data.url_slug);
      navigate('/admin');
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div style={containerStyle}>
      <div style={formStyle}>
        <img src={logo} alt="Logo" style={logoStyle} />
        <Title level={3}>OutScore</Title>
        <Title level={4}>Login</Title>

        <Form onFinish={handleSubmit} layout="vertical" style={formContainerStyle}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              style={inputStyle}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              style={inputStyle}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={buttonStyle}>
              Login
            </Button>
          </Form.Item>
        </Form>
        <Button onClick={() => navigate('/signup')} style={buttonStyle}>
          Sign Up
        </Button>
      </div>
    </div>
  );
  
}

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  //height: '100vh',
  padding: '20px',
};

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '400px',
};

const formContainerStyle = {
  width: '100%',
};

const logoStyle = {
  width: '150px',
  marginBottom: '20px',
};

const inputStyle = {
  width: '100%',
};

const buttonStyle = {
  width: '100%',
};

export default LoginPage;